const BASE_URL = "https://crm-backend-tiix.vercel.app";
const BASE_URL_2 = "https://tutoring-server.onrender.com";

export const sendAssessment = async (data) => {
  var options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
    }),
  };

  try {
    const response = await fetch(`${BASE_URL_2}/send`, options);

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
};

export const addReview = async (data) => {
  var options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: data.name,
      review: data.review,
      stars: data.stars,
    }),
  };

  try {
    const response = await fetch(`${BASE_URL}/site/add-review`, options);

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
};

export const listReview = async () => {
  var options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${BASE_URL}/site/list-reviews`, options);

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
};
