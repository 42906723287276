import React, { useEffect } from "react";
import contact from "../assets/contact.png";
import { sendAssessment } from "../Utils/Api";

export default function AssessmentForm() {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState({
    type: "", //Error or Success
    message: "",
  });
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData({ ...formData, [name]: value });
  };

  const isFormValid = () => {
    const { name, email, phone, subject, message } = formData;
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      subject === "" ||
      message === ""
    ) {
      return false;
    }
    if (!email.includes("@") || !email.includes(".")) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    console.log(formData);
    setLoading(true);
    try {
      const response = await sendAssessment(formData);
      if (response) {
        setLoading(false);
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      }

      setMessage({
        type: "success",
        message: "Message sent successfully. Thank you!",
      });
    } catch (error) {
      console.log(error.error);
      setMessage({
        type: "error",
        message: error.error,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage({
        type: "",
        message: "",
      });
    }, 5000);

    return () => clearTimeout(timer);
  }, [message]);

  return (
    <div
      id="form"
      className="w-full flex justify-center items-center bg-rose-50 bg-opacity-50"
    >
      <div className="w-full xl:max-w-[80rem] px-5 md:px-10 xl:px-0 py-20">
        <div className="flex flex-col gap-10 items-center">
          {/* Heading */}
          <div className="flex flex-col gap-2 items-center ">
            <div>
              <img src={contact} alt="logo" className="w-11" />
            </div>
            <h1 className="font-bold font-pop text-5xl text-center">
              Assessment Form
            </h1>
            <p className="text-sm text-zinc-600 text-center">
              Fill out the form below and we will get back to you as soon as we
              can.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="w-full flex justify-center ">
            <div className="w-full sm:w-[80%] md:w-[60%] lg:w-[44%]">
              <div className="flex flex-col gap-3">
                {/* Name */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="name"
                    className="font-pop font-medium text-xl"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    value={formData.name}
                    placeholder="Enter your name"
                    disabled={loading}
                    className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                  />
                </div>

                {/* Email */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className="font-pop font-medium text-xl"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    value={formData.email}
                    placeholder="Enter your email"
                    disabled={loading}
                    className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                  />
                </div>

                {/* Phone */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="phone"
                    className="font-pop font-medium text-xl"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    onChange={handleChange}
                    value={formData.phone}
                    placeholder="Enter your phone number"
                    disabled={loading}
                    className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                  />
                </div>

                {/* Subject */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="subject"
                    className="font-pop font-medium text-xl"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    onChange={handleChange}
                    value={formData.subject}
                    placeholder="Enter the subject"
                    disabled={loading}
                    className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                  />
                </div>

                {/* Message */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="message"
                    className="font-pop font-medium text-xl"
                  >
                    Write your message
                  </label>
                  <div>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="6"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Enter your message"
                      disabled={loading}
                      className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="w-full bg-rose-500 text-white font-pop font-bold text-xl py-2 rounded-md"
                  >
                    {loading ? "Sending..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>

          {/* Message */}
          <div className="font-medium">
            {message.message &&
              (message.type === "success" ? (
                <p className="text-green-500">{message.message}</p>
              ) : (
                <p className="text-red-500">{message.message}</p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
