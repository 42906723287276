import React, { useEffect, useState } from "react";
import love from "../assets/lottie/love.json";
import Lottie from "lottie-react";
import { StarIcon } from "./Icons";
import { addReview } from "../Utils/Api";

export default function Review() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "", //Error or Success
    message: "",
  });
  const [wordCount, setWordCount] = useState(0);
  const maxWords = 60;

  const formFormat = {
    name: "",
    review: "",
    stars: 0,
  };
  const [formData, setFormData] = useState(formFormat);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "review") {
      setWordCount(value.split(" ").filter(Boolean).length);

      if (value.split(" ").filter(Boolean).length > maxWords) {
        console.log(true);
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleStarBtn = (n) => {
    setFormData({ ...formData, stars: n + 1 });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, review, stars } = formData;
    setLoading(true);
    try {
      if (name === "" || review === "" || stars === 0)
        throw new Error("Please fill all the fields");
      const response = await addReview(formData);
      if (response) {
        setLoading(false);
        setFormData(formFormat);
      }
      setMessage({
        type: "success",
        message: "Review added successfully. Thanks you!",
      });
    } catch (error) {
      console.log(error.message);
      setMessage({
        type: "error",
        message: error.message,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage({
        type: "",
        message: "",
      });
    }, 5000);

    return () => clearTimeout(timer);
  }, [message]);

  return (
    <div
      id="review"
      className="w-full flex justify-center items-center bg-bg-sky"
    >
      <div className="w-full xl:max-w-[80rem] px-5 md:px-10 xl:px-0 py-20">
        <div className="flex flex-col gap-5 items-center">
          {/* Heading */}
          <div className="flex flex-col gap-2 items-center ">
            <div>
              <Lottie animationData={love} loop={true} className="w-20" />
            </div>
            <h1 className="font-bold font-pop text-5xl text-center">
              Leave a Review
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="w-full flex justify-center ">
            <div className="w-full sm:w-[80%] md:w-[60%] lg:w-[44%]">
              <div className="flex flex-col gap-3">
                {/* Stars */}
                <div className="w-full flex justify-center py-5">
                  <div className="flex items-center gap-1">
                    {[...Array(5)].map((_, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleStarBtn(i)}
                        className="outline-none selection:bg-none"
                      >
                        <StarIcon
                          key={i}
                          className={`w-9 h-9
                          ${i < formData.stars ? "fill-yellow-400" : ""}
                        `}
                          stroke={i < formData.stars ? "#FFC107" : "#cccccc"}
                        />
                      </button>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="name"
                    className="font-pop font-medium text-xl"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    value={formData.name}
                    placeholder="Enter your name"
                    className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="review"
                    className="font-pop font-medium text-xl"
                  >
                    Write your review
                  </label>
                  <div>
                    <textarea
                      name="review"
                      id="review"
                      cols="30"
                      rows="6"
                      value={formData.review}
                      onChange={handleChange}
                      placeholder="Enter your review"
                      className="w-full border border-zinc-200 rounded-md p-2 outline-none focus:border-sky-500"
                    />
                    <p className="text-sm text-right">
                      {wordCount}/{maxWords}
                    </p>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="w-full bg-rose-500 text-white font-pop font-bold text-xl py-2 rounded-md"
                  >
                    {loading ? "Sending..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>

          {/* Message */}
          <div className="font-medium">
            {message.message &&
              (message.type === "success" ? (
                <p className="text-green-500">{message.message}</p>
              ) : (
                <p className="text-red-500">{message.message}</p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
