import React from "react";

// Assets Imports
import step1 from "../../assets/work/step1.jpg";
import step2 from "../../assets/work/step2.jpg";
import step3 from "../../assets/work/step3.jpg";
import process from "../../assets/work/process.jpg";
import smile from "../../assets/svg/smile.svg";
import trophy from "../../assets/svg/trophy.svg";
import shield from "../../assets/svg/shield.svg";
import maths from "../../assets/subjects/maths.jpg";
import eng from "../../assets/subjects/eng.jpg";
import sci from "../../assets/subjects/sci.jpg";
import art from "../../assets/subjects/art.jpg";
import { Pyramid, RightArrow } from "../../Components/Icons";

// Lib Imports
import { motion } from "framer-motion";
import { Link as A } from "react-scroll";

export default function Work() {
  const cards = [
    {
      id: 1,
      img: step1,
      bg: "bg-orange-500",
      title: "Contact Us",
      p: "Reach out to us via phone or email to express your interest in our tutoring services. Our friendly staff will guide you through the enrolment process, answer any questions you may have.",
    },
    {
      id: 2,
      img: step2,
      bg: "bg-violet-500",
      title: "Consultation",
      p: "Schedule a consultation with one of our experienced educators. During the consultation, we'll take the time to understand your learning needs, academic goals, and any specific areas you'd like to focus on.",
    },
    {
      id: 3,
      img: step3,
      bg: "bg-green-500",
      title: "Select Program",
      p: "Choose the tutoring program that best aligns with your requirements. We offer a range of programs covering various subjects and grade levels.",
    },
  ];
  const subjects = [
    {
      id: 1,
      name: "Mathematics",
      src: maths,
    },
    {
      id: 2,
      name: "English",
      src: eng,
    },
    {
      id: 3,
      name: "Science",
      src: sci,
    },
    {
      id: 4,
      name: "Art",
      src: art,
    },
  ];

  const handleCTA = () => {
    window.open(
      "https://crm-education.vercel.app/form",
      "_blank", // <- This is what makes it open in a new window.
      "rel=noopener noreferrer"
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
      {/* Section 1 */}
      <div className="py-24 pb-28 bg-secondary flex justify-center px-5 md:px-10 xl:px-0">
        <div className="w-full xl:max-w-[80rem] px-5 md:px-10 2xl:px-0 flex flex-col gap-5">
          {/* headings */}
          <div className="flex flex-col justify-center items-center gap-2 text-[#000]">
            <h3 className=" text-xl">Making ease for everyone</h3>
            <h1 className="font-semibold font-pop text-4xl text-center">
              How Our Work Process
            </h1>
          </div>

          {/* paragraph */}
          <div className="w-full flex justify-center">
            <p className="md:w-[70%] text-center text-[16px] text-zinc-600 ">
              At High Hopes Tutoring Centre, we have designed a simple and
              seamless enrolment process to ensure that you can quickly get
              started on your learning journey with us. Here's how it works:
            </p>
          </div>

          {/* Enrolment process Cards */}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
            {cards.map((card, index) => (
              <div
                key={card.id}
                className="border bg-white rounded-md flex flex-col hover:shadow-xl transition-all duration-300"
              >
                <div className="w-full flex justify-center items-center">
                  <img src={card.img} alt="" />
                </div>
                <div className="p-4 md:p-6 flex flex-col gap-3">
                  <h3
                    className={`text-[15px] uppercase max-w-[max-content] rounded-[3px] px-3 py-1 font-medium text-white ${card.bg}`}
                  >
                    Step 0{index + 1}
                  </h3>
                  <h1 className="font-semibold text-[17px]"> {card.title} </h1>
                  <p className="text-justify">{card.p}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="flex gap-6 px-5 xl:px-0">
        <div className="w-[30%] xl:flex-1 hidden md:block">
          <img src={process} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="flex-1 p-4 flex flex-col gap-6 py-24">
          <div className="w-full xl:w-[65%] flex flex-col gap-2">
            <h3 className=" text-xl">Why choose us?</h3>
            <h1 className="font-semibold font-pop text-4xl text-[#000]">
              We take pride in providing excellence. Here's why.
            </h1>
          </div>
          <div className="xl:w-[60%] flex flex-col gap-8">
            <div className="flex gap-5 ">
              <div>
                <div className="w-16 h-16 bg-[#ffeeee] rounded-full flex items-center justify-center">
                  <img src={shield} alt="" className="w-7 h-7" />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="font-semibold text-2xl">
                  Experienced and Dedicated Tutors
                </h1>
                <p>
                  Our highly skilled and passionate tutors are dedicated to
                  ensuring student success through personalized support and
                  guidance.
                </p>
              </div>
            </div>
            <div className="flex gap-5 ">
              <div className="">
                <div className="w-16 h-16 bg-[#F1EEFF] flex items-center justify-center rounded-full">
                  <img src={smile} alt="" className="w-6 h-6" />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="font-semibold text-2xl">
                  Personalized Approach
                </h1>
                <p>
                  We tailor our tutoring sessions to meet each student's unique
                  learning needs, fostering a supportive environment for
                  academic growth and confidence.
                </p>
              </div>
            </div>
            <div className="flex gap-5 ">
              <div>
                <div className="w-16 h-16 bg-[#E7F8F5] rounded-full flex items-center justify-center">
                  <img src={trophy} alt="trophy" className="w-12 h-12" />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="font-semibold text-2xl">Proven Track Record</h1>
                <p>
                  Our tutoring programs have a successful history of helping
                  students achieve academic excellence, providing reliable
                  support for achieving educational goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="flex justify-center bg-secondary">
        <div className="w-full xl:max-w-[80rem] px-4 py-24 flex flex-col gap-10 items-center">
          <div className="flex flex-col items-center gap-1">
            <Pyramid className="w-9 h-9" />
            <h1 className="text-4xl font-semibold mb-4 text-[#000]">
              Curriculum
            </h1>
          </div>
          <div className="flex flex-col gap-5 items-center">
            <div className="w-32 text-center border border-zinc-400 px-3 py-1 rounded-full font-semibold bg-pink-200  text-zinc-700 text-[14px]">
              TIMINGS
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Weekdays */}
              <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-bold mb-4">Weekdays (Mon - Fri)</h2>
                <p className="text-gray-600 mb-4">Timings: 4:00 PM - 7:00 PM</p>
                <p className="text-gray-600">
                  Tutoring Sessions: One-hour sessions for each subject.
                </p>
              </div>

              {/* Weekends */}
              <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-bold mb-4">Weekends (Sat & Sun)</h2>
                <p className="text-gray-600 mb-4">Timings: 9:00 AM - 2:00 PM</p>
                <p className="text-gray-600">
                  Tutoring Sessions: One-hour sessions for each subject.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-5 items-center ">
            <div className="w-32 text-center px-3 py-1 border border-zinc-400 rounded-full font-semibold bg-pink-200  text-zinc-700 text-[14px]">
              PROGRAMS
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6">
              {/* Subjects */}
              {subjects.map((sub) => (
                <div
                  key={sub.id}
                  className="relative rounded-md bg-transparent flex flex-col gap-4 transition-all duration-300"
                >
                  <div className="rounded-md overflow-hidden shadow-sm">
                    <img
                      src={sub.src}
                      alt=""
                      className="w-full h-[200px] object-cover"
                    />
                  </div>
                  <div className="p-5 border rounded-md bg-white hover:shadow-md transition-all duration-300">
                    <h2 className="text-2xl font-semibold text-center text- z-20">
                      {sub.name}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="pt-7">
            <A
              to="form"
              smooth={true}
              duration={500}
              offset={-50}
              className="relative w-max cursor-pointer flex gap-1 items-center  px-5 py-3 rounded-[4px] bg-gradient-to-r from-amber-500 to-rose-500 hover:shadow-xl transition-all duration-150"
            >
              <span className="font-semibold text-[17px] text-white">
                Start your journey
              </span>
              <RightArrow className="w-8 h-8" color="white" />
            </A>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
