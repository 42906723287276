import React from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-scroll";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer";
import Gallery from "./Pages/Gallery/Gallery";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Work from "./Pages/Work/Work";
import Review from "./Components/Review";
import review from "./assets/svg/review.svg";
import contact from "./assets/svg/assessment.svg";
import ScrollToTop from "./Components/ScrollToTop";
import AssessmentForm from "./Components/AssessmentForm";

function App() {
  const [isVisible, setIsVisible] = React.useState(false);
  return (
    <div className="text-black font-pop">
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <AssessmentForm />
        <Review />
        <Footer />
      </ScrollToTop>

      {/* Hover buttons */}
      <div className="fixed bottom-3 right-3 flex flex-col gap-1 justify-end">
        <div className="flex items-center justify-end space-x-3">
          <AnimatePresence>
            {isVisible && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className="right-5 bg-[#000] text-white px-3 py-2 rounded-md text-[14px] mt-1"
              >
                Review Us!
              </motion.div>
            )}
          </AnimatePresence>
          <Link
            to="review"
            smooth={true}
            duration={500}
            offset={-10}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            <motion.img
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              src={review}
              alt="Review Icon"
              className="w-10 h-10 cursor-pointer"
            />
          </Link>
        </div>
        <div className="flex items-center justify-end space-x-3">
          <AnimatePresence>
            {isVisible && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className="right-5 bg-[#000] text-white px-3 py-2 rounded-md text-[14px] mt-1"
              >
                Contact Us!
              </motion.div>
            )}
          </AnimatePresence>
          <Link
            to="form"
            smooth={true}
            duration={500}
            offset={-10}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            <motion.img
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              src={contact}
              alt="Contact Icon"
              className="w-9 h-9 cursor-pointer"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
