import React from "react";
import { LocationIcon, Mail, Phone, SunIcon } from "../../Components/Icons";
import { faqs } from "./faqs.js";
import { AnimatePresence, motion } from "framer-motion";

export default function Contact() {
  const [active, setActive] = React.useState(null);
  const contactInfo = [
    {
      id: 1,
      name: "Phone",
      icon: <Phone className="w-7 h-7" />,
      info: "0404-497-732",
      link: "tel:+0404497732",
    },
    {
      id: 2,
      name: "Email",
      icon: <Mail className="w-7 h-7" />,
      info: "highhopestutoring.canningvale@gmail.com",
      link: "mailto:highhopestutoring.canningvale@gmail.com",
    },
    {
      id: 3,
      name: "Address",
      icon: <LocationIcon className="w-8 h-8" />,
      info: "Suite 18, 2 Batman Road Canning Vale",
      link: "https://goo.gl/maps/swBjHWbrr8eXZZPb7",
    },
  ];

  const handleClick = (index) => {
    if (index === active) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="w-full flex flex-col justify-center items-center relative font-arch "
    >
      <div className="min-h-[92vh] w-full xl:max-w-[80rem] px-5 md:px-10 2xl:px-0 flex justify-center items-center font-sans ">
        <div className="flex flex-col gap-10 ">
          <div className="flex flex-col gap-3 items-center text-center py-10">
            <h3 className="font-bold text-[#000]">WELCOME</h3>
            <h1 className="text-5xl md:text-6xl font-medium text-[#000] w-[75%] text-center">
              Swing by for a cup of coffee.
            </h1>
            <p>
              We want to hear from you. Let us know how we can help you and your
              child.
            </p>
          </div>

          <div className="flex flex-col items-center gap-5">
            <div className="px-3 py-1 rounded-full font-semibold bg-orange-200 text-zinc-700 text-[14px]">
              CONTACT INFORMATION
            </div>
            <div className="w-full sm:w-[50%] lg:w-full grid-cols-1 grid lg:grid-cols-3 grid-flow-row gap-5">
              {contactInfo.map((item) => (
                <a
                  href={item.link}
                  key={item.id}
                  className="rounded-md transition-all duration-300 px-4 py-10 flex flex-col justify-center items-center gap-3 border border-zinc-400 shadow-xl hover:shadow-md hover:bg-green-50 hover:text-sky-600 bg-white"
                >
                  <div className="rounded-full bg-purple-100 border border-black p-3">
                    {item.icon}
                  </div>
                  <div className="break-all text-center font-pop">
                    {item.info}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div className="py-20 w-full xl:max-w-[80rem]">
        <div className="flex flex-col items-center gap-5">
          <div className="px-3 py-1 rounded-full font-semibold bg-pink-200  text-zinc-700 text-[14px]">
            WHAT DO YOU WANT TO KNOW?
          </div>
          <h2 className="text-4xl font-medium w-[70%] text-center">
            Some of the most frequently asked questions
          </h2>
          <div className="flex flex-col items-center gap-5 w-full">
            {faqs.map((item, index) => (
              <motion.button
                key={item.id}
                onClick={() => handleClick(index)}
                className={`border border-black min-w-[45%] font-pop flex flex-col items-center gap-5 p-4 rounded-xl bg-white transition-all shadow-cusShadow overflow-hidden outline-none
                  ${
                    index === active
                      ? "w-[95%] sm:w-[65%] lg:w-[49%] bg-yellow-50"
                      : "w-[85%] sm:w-[60%] lg:w-[45%] "
                  }
                `}
                layout
              >
                <div className="flex flex-col w-full gap-3">
                  <motion.div
                    className="flex justify-between items-center gap-2"
                    layout
                  >
                    <h3 className="text-xl font-medium text-left">
                      {item.question}
                    </h3>
                    <div>{active === index ? "-" : "+"}</div>
                  </motion.div>
                  <AnimatePresence initial={false}>
                    {active === index && (
                      <motion.div
                        className="overflow-hidden"
                        initial={{ opacity: 0, maxHeight: 0 }}
                        animate={{ opacity: 1, maxHeight: "200px" }} // Adjust the value to a suitable maximum height for your answer content
                        exit={{ opacity: 0, maxHeight: 0 }}
                        transition={{
                          opacity: { ease: "linear" },
                          maxHeight: { duration: 0.3 },
                        }}
                      >
                        <p className="text-left">{item.answer}</p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.button>
            ))}
          </div>
        </div>
      </div>

      {/* design Lines */}
      <div className="fixed w-full h-full flex justify-center top-0 z-[-99]">
        <div className="w-full max-w-[100rem] h-full flex gap-12 justify-evenly">
          <div className="border-l border-zinc-100 "></div>
          <div className="border-l border-zinc-100 flex items-center">
            <div className="-ml-5">
              <motion.div
                className="w-10 h-10 rounded-full flex justify-center items-center"
                layout
                animate={{
                  rotate: [0, 360], // Rotate from 0 degrees to 360 degrees
                }}
                transition={{
                  duration: 10,
                  repeat: Infinity, // Repeat the animation indefinitely
                  repeatType: "loop", // Restart the animation after each repeat
                  ease: "linear", // Use linear easing for smooth rotation
                }}
              >
                <SunIcon className="w-10 h-10" color="rgb(212 212 216)" />
              </motion.div>
            </div>
          </div>
          <div className="border-l border-zinc-100 "></div>
          <div className="border-l border-zinc-100 "></div>
          <div className="border-l border-zinc-100 "></div>
          <div className="border-l border-zinc-100 sm:block hidden"></div>
          <div className="border-l border-zinc-100 md:block hidden "></div>
          <div className="border-l border-zinc-100 md:block hidden"></div>
          <div className="border-l border-zinc-100 lg:block hidden"></div>
          <div className="border-l border-zinc-100 lg:block hidden"></div>
          <div className="border-l border-zinc-100 xl:block hidden"></div>
          <div className="border-x border-zinc-100 xl:block hidden"></div>
        </div>
      </div>
    </motion.div>
  );
}
