import React from "react";
import { StarIcon } from "../../Components/Icons";

export default function ReviewCard({ review, rating, name }) {
  return (
    <div className="xl:max-w-[20rem] w-full border bg-slate-50 p-4 rounded-xl flex items-center justify-center">
      <div className="flex flex-col items-center gap-3 ">
        <div className="flex flex-col items-center">
          <h1 className="font-semibold text-lg capitalize"> {name} </h1>
          <div className="text-sm flex items-center">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="outline-none selection:bg-none">
                <StarIcon
                  key={i}
                  className={`w-5 h-5
                    ${i < rating ? "fill-yellow-400" : ""}
                  `}
                  stroke={i < rating ? "#FFC107" : "#cccccc"}
                />
              </div>
            ))}
          </div>
        </div>

        <div>
          <p className="text-center text-sm">{review}</p>
        </div>
      </div>
    </div>
  );
}
