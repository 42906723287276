export const faqs = [
  {
    id: 1,
    question: "What subjects do you offer tutoring in?",
    answer:
      "We offer tutoring in a wide range of subjects, including math, science, English, languages, and more. Our tutors are well-versed in the Australian school curriculum, catering to various grade levels.",
  },
  {
    id: 2,
    question: "How are your tutors selected?",
    answer:
      "Our tutors undergo a rigorous selection process. We carefully evaluate their qualifications, teaching experience, subject expertise, and passion for education. Rest assured, our tutors are dedicated professionals committed to helping students succeed.",
  },
  {
    id: 3,
    question: "What is the class size in your small group sessions?",
    answer:
      "To ensure individual attention, our small group sessions have a maximum of only 5 students per group. This allows our tutors to focus on each student's unique needs and provide personalized support.",
  },
  {
    id: 4,
    question: "Is your tutoring center suitable for all grade levels?",
    answer:
      "Absolutely! Our tutoring services cater to students from primary to high school. Whether your child needs foundational support or advanced guidance, we have the expertise to assist them at any academic level.",
  },
  {
    id: 5,
    question: "What makes your tutoring center different from others?",
    answer:
      "At High Hopes Tutoring Centre, we take pride in our student-centered approach. Our interactive teaching methods, passionate tutors, and commitment to real learning set us apart from traditional worksheet-based tutoring centers.",
  },
  {
    id: 6,
    question: "What are the payment options available?",
    answer:
      "We offer easy payment options to suit your convenience. You can make payments through Ezi-Debit on a weekly or fortnightly basis, ensuring a hassle-free payment process.",
  },
  {
    id: 7,
    question: "How do I get started with tutoring sessions?",
    answer:
      "Getting started is simple! You can contact us via phone, email, or the contact form on our website. Our friendly team will assist you in selecting the best tutoring program for your needs and schedule your first session.",
  },
  {
    id: 8,
    question: "What are your operating hours?",
    answer:
      "We are open from 3:30 PM to 7:30 PM on weekdays and 9:00 AM to 1:00 PM on Saturdays. We are closed on Sundays and public holidays.",
  },
  {
    id: 9,
    question: "Do you offer specialized programs for test preparation?",
    answer:
      "Yes, we provide specialized test preparation programs for various exams, such as NAPLAN, HSC, and more. Our tailored approach helps students build confidence and excel in their exams.",
  },
  {
    id: 10,
    question: "Are there any discounts or promotions available?",
    answer:
      "Keep an eye on our website and social media channels for any ongoing promotions or discounts. We occasionally offer special deals and incentives to make our tutoring services even more accessible.",
  },
];
