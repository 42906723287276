import React, { useEffect, useState } from "react";
import ReviewCard from "./ReviewCard";
import Loader from "../../Components/Loader";
import { motion } from "framer-motion";
import { listReview } from "../../Utils/Api";
import { storage } from "../../Utils/Firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

export default function Gallery() {
  const [reviews, setReviews] = useState([]);
  const [imageList, setImageList] = useState([]); // [ {name: "abc", url: "https://..."}, ...]
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false); // [ {name: "abc", url: "https://..."}, ...

  const fetchReviews = async () => {
    setLoading(true);

    try {
      const response = await listReview();
      setReviews(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchImages = async () => {
    setLoadingImg(true);
    try {
      const listRef = ref(storage, "gallery");
      const res = await listAll(listRef);
      const promises = res.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return { name: item.name, url };
      });
      const urls = await Promise.all(promises);
      setImageList(urls);
      setLoadingImg(false);
    } catch (error) {
      console.error(error);
      setLoadingImg(false);
    }
  };

  useEffect(() => {
    fetchReviews();
    fetchImages();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * imageList.length);
      setImageIndex(randomIndex);
    }, 3500);

    return () => clearInterval(timer);
  }, [imageIndex, imageList]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full flex flex-col items-center overflow-hidden"
    >
      <div className="w-full bg-bg-sky flex flex-col gap-20 items-center justify-center py-16 pb-20 ">
        <div className="w-full xl:max-w-[80rem]  flex flex-col gap-10 overflow-hidden">
          <h1 className="text-5xl font-extrabold px-5 md:px-10 xl:px-0">
            {" "}
            GALLERY{" "}
          </h1>
          <div className="flex gap-6 w-full py-5 overflow-hidden">
            {loadingImg ? (
              <Loader />
            ) : (
              imageList.length > 0 && (
                <div className="flex gap-3 overflow-x-auto py-10 first:pl-5 last:pr-5">
                  {imageList.map((item, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => setImageIndex(index)}
                        className={`min-w-[150px] h-[150px] p-3 rounded-xl shadow-xl hover:scale-105 transition-all selection:bg-none 
                          ${
                            imageIndex === index
                              ? "scale-[1.1] bg-sky-200"
                              : "bg-white"
                          }
                        `}
                      >
                        <img
                          src={item?.url}
                          alt=""
                          className="h-full w-full object-cover shadow-md rounded-md"
                        />
                      </button>
                    );
                  })}
                </div>
              )
            )}
          </div>
        </div>
        <div className="w-full flex justify-center px-5">
          <div className="max-w-[32rem]">
            {loadingImg ? (
              <div className="w-full h-[32rem] flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                src={imageList[imageIndex]?.url}
                alt="gallery"
                className="shadow-xl rounded-md transition-all"
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-full xl:max-w-[80rem] px-5 md:px-10 2xl:px-0 flex flex-col gap-10 py-16">
        <h1 className="text-3xl font-extrabold"> REVIEWS </h1>
        {loading ? (
          <Loader />
        ) : reviews && reviews.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 w-full">
            {reviews.map((review) => (
              <ReviewCard
                key={review._id}
                review={review.review}
                rating={review.stars}
                name={review.name}
              />
            ))}
          </div>
        ) : (
          <h1 className="text-2xl font-semibold"> No Reviews Yet </h1>
        )}
      </div>
    </motion.div>
  );
}
