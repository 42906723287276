import React, { useEffect, useState } from "react";
import { Pyramid, RightArrow } from "../../Components/Icons";
import expert from "../../assets/features/expert.jpg";
import curr from "../../assets/features/curr.jpg";
import group from "../../assets/features/group.jpg";
import learning from "../../assets/features/learning.jpg";
import growth from "../../assets/features/growth.jpg";
import payment from "../../assets/features/payment.jpg";
import test from "../../assets/svg/test.svg";
import next from "../../assets/svg/next.svg";
import HeroHome from "../../assets/svg/HeroHome.svg";
import Typewriter from "typewriter-effect";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Link as A } from "react-scroll";
import redirect from "../../assets/svg/redirect.svg";
import redirect2 from "../../assets/redirect2.svg";

export default function Home() {
  const [active, setActive] = useState(0);
  const words = ["A bright future", "Self confidence", "Learning"];
  const features = [
    {
      id: 1,
      title: "Small Group Sessions, Maximum Attention",
      description:
        "Experience personalized learning in small group sessions, where we keep the class sizes intimate with a maximum of only 5 students per group. This ensures that every student receives dedicated attention from our expert tutors, fostering a dynamic and engaging learning environment.",
      image: group,
    },
    {
      id: 2,
      title: "Beyond Worksheets - Real Learning",
      description:
        "At High Hopes Tutoring Centre, we believe in active teaching, not just handing out worksheets. Our experienced educators engage students through interactive lessons, practical applications, and stimulating discussions, making learning enjoyable and impactful.",
      image: learning,
    },
    {
      id: 3,
      title: "Empowering Student Growth",
      description:
        "Our mission is to empower students to unlock their full potential. We foster critical thinking, problem-solving skills, and a love for learning that extends far beyond the classroom. With us, students gain the confidence to face challenges and achieve academic excellence.",
      image: growth,
    },

    {
      id: 5,
      title: "Convenient & Flexible Payment Options",
      description:
        "We understand the importance of easy payment options for busy parents. With Ezi-Debit payment available, you can choose to pay weekly or fortnightly, making it convenient for your family's budget while ensuring seamless access to top-notch tutoring services.",
      image: payment,
    },
    {
      id: 4,
      title: "Focused on Australian School Curriculum",
      description:
        "Our tutoring is tailored to align perfectly with the Australian school curriculum, ensuring that students receive targeted support in the subjects they need assistance with the most. We help students not only grasp the concepts but also excel in their academic journey.",
      image: curr,
    },
    {
      id: 6,
      title: "Expert and Passionate Tutors",
      description:
        "Our tutors are subject matter experts, deeply passionate about teaching. With personalized attention, they nurture a positive environment where students thrive. Empowering you to excel academically and beyond, join us in building a brighter future!",
      image: expert,
    },
  ];
  const testimonoials = [
    {
      id: 1,
      name: "Sween Saini",
      description:
        "It's not an educational venture like any other commercial training hub. This is a place where soul & sweat has been instilled by passionate people. Gaining huge success in short time because the goal is to contribute a meaningful role in young lives through grooming & coaching. We are so proud and contented and wish huge success to everyone coming with Highly Hopes.",
    },
    {
      id: 2,
      name: "Amit Meel",
      description:
        "Highly recommended is High Hopes, a migration and education consulting firm. High Hopes Migration Perth is a highly professional company that gives honest advice and is easy to work with.  Raghav at High Hopes Migration Perth comes highly recommended by me. I wish you the best of luck.",
    },
    {
      id: 3,
      name: "Manisha Dhadli",
      description:
        "Your expertise in teaching has put our minds at ease. We are so grateful to have you as our child's teacher. Our child comes home every day telling us stories about how fun it is to be in your classroom. Your practical based learning and one on one approach is amazing! Thank you for instilling this love of learning into our child. It has been such a pleasure watching him grow this year. Our child's skills have developed considerably while under your care.",
    },
    {
      id: 4,
      name: "Saloni Mahajan",
      description:
        "Mukta Sodhi is very dedicated and passionate educator. Thank you for your hard work in developing our son's literacy and numeracy skills. She always shown commitment and loves for our child and put in so much of her own time and efforts. Thank you so much and keep up the hard work.",
    },
    {
      id: 5,
      name: "Ranjit Dhaliwal",
      description:
        "My son was really struggling in school, but high hopes tutoring center has really helped him with studies, each student is getting attention in their area of needs. I am so glad that my son has made huge improvement. No amount of admiration is enough to praise high hopes and the help they have been to parents with educationally struggling kids. I would highly recommend high hopes to everyone.",
    },
    {
      id: 6,
      name: "Manpreet Singh",
      description:
        "Highly recommended... I have seen so much improvement in my daughter since she started learning from high hopes. Thank you so much Mukta.",
    },
  ];

  const handleNext = () => {
    if (active === testimonoials.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const handlePrev = () => {
    if (active === 0) {
      setActive(testimonoials.length - 1);
    } else {
      setActive(active - 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (active === testimonoials.length - 1) {
        setActive(0);
      } else {
        setActive(active + 1);
      }
    }, 6000);

    return () => clearInterval(interval);
  }, [testimonoials.length, active]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full flex flex-col items-center "
    >
      <div className="min-h-[92vh] xl:max-w-[80rem] px-5 md:px-10 2xl:px-0 flex justify-center items-center">
        <div className=" flex items-center gap-8">
          {/* left side */}
          <div className="flex-1 flex flex-col gap-6">
            <div className="flex flex-col gap-4 sm:w-[68%] lg:w-full">
              <h1 className="font-pop font-bold text-[3rem] sm:text-[3.3rem] leading-[3.4rem] sm:leading-[4rem]">
                A good{" "}
                <span className="text-sky-500">
                  <span className="font-pop text-4xl sm:text-5xl">#</span>
                  education
                </span>{" "}
                is always a base of
              </h1>
              <div className="w-[max-content]">
                <h1 className="font-pop text-3xl sm:text-5xl text-white bg-[#6A307D] font- font-semibold px-4 py-3 rounded-md tracking-wide">
                  <Typewriter
                    options={{
                      strings: words,
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h1>
              </div>
              <div>
                <p className="text-[18px] text-zinc-600">
                  We aim to match every student with Experienced and
                  Professional tutors that will ignite their passion for
                  Learning.
                </p>
              </div>
            </div>

            {/* CTA */}
            <div className="relative flex flex-wrap gap-3">
              <Link
                to="https://forms.gle/GvRJAeq2UqyTNeka6"
                target="_blank"
                className="w-full max-w-[14rem] cursor-pointer flex gap-1 items-center justify-between px-5 py-3 rounded-[4px] bg-gradient-to-r from-amber-500 to-rose-500 hover:shadow-xl transition-all duration-150 border-0 overflow-hidden relative"
              >
                <span className="font-semibold text-[17px] text-white">
                  Enroll Now
                </span>
                <img
                  src={redirect2}
                  className="w-5 h-5 z-10 absolute top-2 right-2"
                  alt="redirct"
                />
              </Link>
              {/* Tooltip */}
              <div className="hidden absolute -top-5 2xl:-top-10 left-[30rem] 2xl:-left-28 sm:flex flex-col items-start 2xl:items-end">
                <p className="font-curs text-xl text-[#757575] w-max 2xl:w-[100px] text-center">
                  start from here
                </p>
                <img
                  className="w-12 h-12 -mt-2 scale-x-[-1] 2xl:scale-x-[1]"
                  src="https://amentotech.com/htmls/tuturn/images/knob_line.svg"
                  alt=""
                />
              </div>

              <A
                to="form"
                smooth={true}
                duration={500}
                offset={-50}
                className="relative w-full max-w-[14rem] cursor-pointer flex gap-1 items-center justify-between px-5 py-3 rounded-[4px] bg-gradient-to-r from-amber-500 to-rose-500 hover:shadow-xl transition-all duration-150"
              >
                <div
                  className="absolute inset-0 top-[2px] left-[2px] right-[2px] bottom-[2px] rounded-[4px] bg-white"
                  style={{ zIndex: 0 }}
                ></div>
                <span className="font-semibold text-[17px] text-black z-10">
                  Book a Free Trial
                </span>
                <RightArrow className="w-8 h-8 z-10" color="#000" />
              </A>
            </div>
          </div>

          {/* right side */}
          <div className="flex-1 items-end hidden lg:flex">
            <div className=" ml-6">
              <img src={HeroHome} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <div className="w-full flex justify-center items-center bg-bg-sky py-20">
        {/* Features */}
        <div className="xl:max-w-[80rem] px-5 md:px-10 2xl:px-0 flex flex-col gap-5">
          {/* headings */}
          <div className="flex flex-col justify-center items-center gap-2">
            <Pyramid className="w-9 h-9" />
            <h3 className="font- text-xl">Better Learning. Better Results</h3>
            <h1 className="font-semibold text-center font-pop text-4xl">
              The basis of better understanding
            </h1>
          </div>

          {/* paragraph */}
          <div className="w-full flex justify-center">
            <p className="md:w-[70%] text-center text-[17px] text-zinc-600">
              Join us at High Hopes Tutoring Centre, where quality education,
              individualized attention, and a supportive learning community
              await your child's academic journey. Together, we'll pave the way
              for a brighter future. Let's achieve greatness together!
            </p>
          </div>

          {/* Cards */}
          <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-3">
            {features.map((feature) => (
              <div
                key={feature.id}
                className="border bg-white rounded-md flex flex-col p-4 gap-3 hover:shadow-xl transition-all duration-300"
              >
                <div className="w-full flex justify-center items-center">
                  <img src={feature?.image} alt="" className="w-[70%]" />
                </div>
                <h1 className="font-medium text-2xl min-h-[max-content] h-16">
                  {" "}
                  {feature.title}{" "}
                </h1>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="w-full flex justify-center items-center py-20 bg-[#FFD05C] relative overflow-hidden">
        <div className="w-full xl:w-[80rem] flex flex-col items-center gap-5 text-[#000]">
          <div className="flex flex-col justify-center items-center gap-2">
            <Pyramid className="w-9 h-9" />
            <h3 className="font-medium text-xl">Testimonials</h3>
            <div className="flex items-center gap-2">
              <div className="pt-2">
                <button onClick={handlePrev}>
                  <img
                    src={next}
                    alt=""
                    className="w-7 h-7 transform rotate-180 transition-all duration-300 hover:scale-110"
                  />
                </button>
              </div>
              <h1 className="font-semibold font-pop text-4xl text-center">
                What Our Members Say
              </h1>
              <div className="pt-2">
                <button onClick={handleNext}>
                  <img
                    src={next}
                    alt=""
                    className="w-7 h-7 transform transition-all duration-300 hover:scale-110 "
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="w-full flex flex-col justify-center items-center relative">
            {/* Large Screens only */}
            <div className="hidden lg:block relative ">
              <img
                src={test}
                alt=""
                className="w-full h-full  border-t-2 border-black"
              />
              {/* <div className="border"> */}
              <AnimatePresence>
                <motion.div className="absolute top-0 left-0 w-full h-full flex justify-center items-center ">
                  <div className="w-[50%] lg:w-[30rem] h-[50%] lg:h-[20rem] flex items-center justify-center overflow-hidden">
                    {testimonoials[active] && (
                      <motion.div
                        key={active}
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 100 }}
                        transition={{ duration: 0.5 }}
                        className=" flex flex-col justify-center items-center gap-3 p-5 overflow-hidden"
                      >
                        <p className="text-center text-[16px] ">
                          {testimonoials[active].description}
                        </p>
                        <h1 className="font-semibold text-xl">
                          - {testimonoials[active].name}
                        </h1>
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
              {/* </div> */}
            </div>

            {/* For Mobile screens only */}
            <div className="w-full px-4 flex justify-center mb-5 lg:hidden">
              <div className="w-full md:w-[50%]">
                <AnimatePresence>
                  {testimonoials[active] && (
                    <motion.div
                      key={active}
                      initial={{ opacity: 0, x: "-100%", position: "absolute" }}
                      animate={{ opacity: 1, x: 0, position: "relative" }}
                      exit={{ opacity: 0, x: "100%", position: "absolute" }}
                      transition={{ duration: 0.5 }}
                      layout
                      className="flex flex-col justify-center items-center gap-3 min-h-[25rem] border bg-slate-50 p-4 rounded-xl"
                    >
                      <p className="text-center text-[15px] md:text-[16px] text-black">
                        {testimonoials[active].description}
                      </p>
                      <h1 className="font-semibold text-xl">
                        - {testimonoials[active].name}
                      </h1>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>

            <div className="flex gap-2">
              {Array.from({ length: testimonoials.length }, (_, i) => (
                <div
                  key={i}
                  onClick={() => setActive(i)}
                  className={`w-2 h-2 rounded-full cursor-pointer transition-all duration-300 ${
                    active === i ? "bg-black w-4" : "bg-white"
                  }`}
                ></div>
              ))}
            </div>
          </div>

          {/* CTA */}
          <div className="pt-10">
            <Link
              to="/gallery"
              className=" px-5 py-3 rounded-[4px] border bg-white bg-opacity-30 hover:bg-opacity-70 transition-all"
            >
              Know More
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
