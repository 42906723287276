import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.jpeg";
import { HamburgerMenuIcon } from "./Icons";
import { AnimatePresence, motion } from "framer-motion";

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const links = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Work",
      path: "/work",
    },
    {
      name: "Gallery",
      path: "/gallery",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  const location = useLocation();
  const findingRoute = (path) => {
    let currentPath = location.pathname.split("/");
    let givenPath = path.split("/");
    if (currentPath[1] === givenPath[1]) return true;
    return false;
  };

  const handleHamburger = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="w-full px-4 min-h-[8vh] flex border-b border-zinc-200 backdrop-blur-sm sticky top-0 bg-white"
      style={{ zIndex: 1000 }}
    >
      <div className="w-full flex gap-3 items-center justify-between">
        {/* Name */}
        <div className="flex items-center gap-2">
          {/* Logo */}
          <div className="w-12 h-12 md:w-16 md:h-16 rounded-full overflow-hidden">
            <img src={logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <div className="font-pop font-bold text-[1rem] md:text-[1.3rem] text-rose-500 flex gap-2">
            High Hopes <span className="sm:flex hidden">|</span>{" "}
            <span className="text-black sm:flex hidden">Tutoring Centre</span>
          </div>
        </div>

        {/* Navlinks */}
        <div className="lg:block hidden z-[999]">
          <ul className="flex text-[15px]">
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.path}
                className={`py-2 px-4 bg-white ${
                  findingRoute(link.path) && "text-red-500 font-medium"
                }`}
              >
                {link.name}
              </Link>
            ))}
          </ul>
        </div>

        {/* Hamburger */}
        <div className="lg:hidden block">
          <button
            onClick={handleHamburger}
            className="outline-none selection:bg-none p-1 rounded-md bg-[#6A307D]"
          >
            <HamburgerMenuIcon className="w-7 h-7" color="white" />
          </button>
        </div>
      </div>

      {/* Hamburger Links */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ maxHeight: 0 }}
            animate={{ maxHeight: "100vh" }}
            exit={{ maxHeight: 0 }}
            transition={{ duration: 0.4, type: "just", ease: "easeInOut" }}
            className="absolute top-[8vh] left-0 w-full bg-white shadow-xl overflow-hidden z-[999] lg:hidden block"
            style={{ zIndex: 1000 }}
          >
            <ul className="flex flex-col text-[15px] ">
              {links.map((link, index) => (
                <Link
                  key={index}
                  to={link.path}
                  onClick={handleHamburger}
                  style={{ zIndex: 1000 }}
                  className={`py-3 px-5 border-b 
                    ${
                      findingRoute(link.path) &&
                      "bg-rose-500 text-white font-medium"
                    }
                  `}
                >
                  {link.name}
                </Link>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
