import React from "react";
import { motion } from "framer-motion";
import sign from "../../assets/about/signature.png";
import mukta from "../../assets/mukta.jpeg";
import mission from "../../assets/about/mission.jpg";
// import joanna from "../../assets/about/team/joanna.jpg";
import rongrong from "../../assets/about/team/rongrong.jpg";

export default function About() {
  const staffDetails = [
    // {
    //   id: 1,
    //   name: "Joanna",
    //   img: joanna,
    //   des: "I have been tutor for over 10 years. I completed a year of exchange at a high school in Japan, on a scholarship. Afterwards, I completed an undergraduate degree in Asian Studies, Specialist in Japanese Language. During the day I work for a non-for-profit outreach education program.",
    //   skills: ["Mathematics", "English", "Japanese"],
    // },
    {
      id: 2,
      name: "Rongrong",
      img: rongrong,
      des: "NA",
      skills: [],
    },
    {
      id: 3,
      name: "Kezia Kuriakose",
      img: "",
      des: (
        <span>
          Studying: Bachelor of Biomedicine <br />
          Achievements: Completion of Yr12, Achieved WACE certificate, ATAR
          score above 90, leadership involvement in school (Prefect in Yr 12/
          service award)
        </span>
      ),
      skills: [],
    },
  ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full flex flex-col items-center"
    >
      <div className="w-full xl:max-w-[80rem] px-5 md:px-10 2xl:px-0">
        <div className="w-full max-h-fit flex lg:flex-row flex-col-reverse gap-6 py-4 px-5 md:px-10 xl:px-0 relative -z-">
          {/* Left */}
          <div className="md:w-[30%] flex items-end">
            <div className=" flex flex-col w-full" style={{ zIndex: 2000 }}>
              <h3 className="py-2 border-b border-zinc-300 text-xl font-semibold">
                {" "}
                Follow Us{" "}
              </h3>
              {/* social links */}
              <a
                href="https://www.instagram.com/high_hopes_tutoring/"
                target="_blank"
                rel="noreferrer"
                className="py-2 border-b border-zinc-300 text-[17px] hover:bg-zinc-100 transition-all"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/Highhopescanningvale/"
                target="_blank"
                rel="noreferrer"
                className="py-2 border-b border-zinc-300 text-[17px] hover:bg-zinc-100 transition-all"
              >
                Facebook
              </a>
            </div>
          </div>

          {/* Right */}
          <div className="lg:w-[70%]">
            <div className="w-full h-full flex flex-col gap-6">
              <h1 className="lg:hidden text-5xl font-bold text-center">
                Director
              </h1>

              <div className="w-full h-[300px] md:h-[500px]">
                <img
                  src={mukta}
                  alt="Director's"
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>
              <div className="text-center lg:hidden">
                <q className="font-medium italic text-zinc-900 text-xl">
                  I inspire students to reach greatness.
                </q>
              </div>
              {/* Right Bottom */}
              <div className="flex flex-col lg:flex-row gap-6">
                <div className="flex-1">
                  <p className="text-justify">
                    As the director and principal founder of High Hopes Tutoring
                    Centre, I, Mukta Sodhi, bring a wealth of expertise to
                    empower students. With a Bachelor of Science from India and
                    a Post Graduation in Education from the University of
                    Western Australia, I have honed my skills in Mathematics and
                    Science education. Having worked as a full-time teacher in
                    various high schools in Perth since 2017, I possess
                    extensive knowledge of the Australian curriculum and
                    understand the standards required to excel in this academic
                    environment.
                  </p>
                </div>
                <div className="flex-1 flex flex-col gap-2">
                  <p className="text-justify">
                    At High Hopes Tutoring Centre, I am fully committed to
                    guiding each student and helping them realize their true
                    potential. Together, we will unlock a world of possibilities
                    and strive for greatness. Let's embark on this incredible
                    journey of learning and growth together!
                  </p>

                  <div className="flex flex-col gap-2">
                    <div>
                      <h3 className="text-lg font-semibold">Qualifications</h3>
                      <p className="text-md">B.Sc, M.Ed</p>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">Experience</h3>
                      <p className="text-md">8+ years</p>
                    </div>
                  </div>
                  <div className="border-b border-zinc-300" />

                  <div className="w-full flex flex-col items-end ">
                    <h3 className="text-xl font-semibold">Mukta Sodhi</h3>
                    <p className="text-lg">Director and Principal</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Absolute quote */}
          <div className="hidden lg:flex absolute top-2 left-0">
            <div className="w-[50%] flex flex-col gap-4">
              <h1 className="md:text-[4.5rem] lg:text-[5.8rem] text-[#000] font-black uppercase leading-[1]">
                I inspire students to reach greatness.
              </h1>
              <div className="">
                <img src={sign} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* Our mission */}
        <div className="bg-white py-20 px-5 md:px-10 xl:px-0">
          <h1 className="text-5xl font-semibold text-center py-4">
            Our Mission
          </h1>
          <div className="flex items-center gap-5">
            <div className="hidden md:flex md:w-[30%] lg:w-full">
              <img src={mission} alt="Our mission" className="w-full" />
            </div>
            <p className="md:w-[70%]">
              At High Hopes Tutoring Centre, our mission is to foster a
              nurturing and inspiring learning environment, empowering students
              to unlock their full potential. We are dedicated to providing
              top-notch academic support, guided by our team of passionate
              educators who strive to instill a love for learning. With a
              commitment to excellence, we aim to equip our students with the
              necessary skills, knowledge, and confidence to thrive academically
              and beyond. Our mission is to shape future leaders who embrace
              challenges with enthusiasm and embrace lifelong learning as a
              cornerstone of success. Together, we aspire to create a brighter,
              knowledge-driven future, where every student can pursue their
              dreams and soar to new heights.
            </p>
          </div>
        </div>

        {/* Staff Details */}
        <div className="py-20 flex flex-col items-center gap-12 px-5 md:px-10 xl:px-0">
          <div className="px-4 py-2 bg-black rounded-md shadow-xl flex items-center gap-5">
            <div className="w-4 h-4 rounded-full bg-white" />
            <h1 className=" text-white text-5xl font-semibold">Staff</h1>
            <div className="w-4 h-4 rounded-full bg-white" />
          </div>
          <div className="flex flex-wrap justify-center gap-10">
            {staffDetails.map((staff) => (
              <div
                key={staff.id}
                className="w-[21rem] flex flex-col items-center gap-8 shadow-xl rounded-xl bg-slate-100 p-5 "
              >
                <div className="w-44 h-44 rounded-full overflow-hidden">
                  {staff.img !== "" ? (
                    <img
                      src={staff?.img}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-red-500 flex items-center justify-center">
                      <h1 className="text-white text-5xl font-semibold">
                        {staff?.name[0]}
                      </h1>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <h1 className="text-2xl font-semibold pb-1 border-b border-zinc-300">
                    {staff?.name}
                  </h1>
                  <p className="p-2 bg-white rounded-md text-[15px]">
                    {staff?.des}
                  </p>
                </div>
                <div className="w-full">
                  <h2 className="font-medium"> Skills </h2>
                  <div className="flex gap-2 items-center">
                    {staff.skills.length > 0 ? (
                      staff.skills.map((skill, index) => (
                        <p
                          key={index}
                          className="text-[14px] px-2 py-1 rounded-md bg-blue-500 text-white"
                        >
                          {skill}
                        </p>
                      ))
                    ) : (
                      <p className="text-[14px] px-2 py-1 rounded-md border bg-white">
                        Not listed
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
