import React from "react";
import dev from "../assets/udhav/w-udhav.jpg";
import { Facebook, Instagram, Mail, Phone, Twitter } from "./Icons";
import { Link } from "react-router-dom";
import logo from "../assets/logo.jpeg";

export default function Footer() {
  const socialLinks = [
    {
      name: "Twitter",
      icon: <Twitter className="w-6 h-6" color="white" />,
      path: "https://www.twitter.com/",
    },
    {
      name: "Facebook",
      icon: <Facebook className="w-7 h-7" color="white" />,
      path: "https://www.facebook.com/Highhopescanningvale/",
    },
    {
      name: "Instagram",
      icon: <Instagram className="w-6 h-6" color="white" />,
      path: "https://www.instagram.com/high_hopes_tutoring/",
    },
  ];

  const contactLinks = [
    {
      name: "0404-497-732",
      icon: <Phone className="w-6 h-6" color="white" />,
      path: "tel:0404497732",
    },
    {
      name: "highhopestutoring.canningvale",
      icon: <Mail className="w-[18px] h-[18px] ml-1" color="white" />,
      path: "mailto:highhopestutoring.canningvale@gmail.com",
    },
  ];

  const usefulLinks = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Gallery",
      path: "/gallery",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];
  return (
    <div>
      <div className="w-full py-20 flex flex-col items-center justify-center bg-primary text-white">
        <div className="w-full xl:max-w-[80rem] px-5 md:px-10 2xl:px-0 flex flex-col gap-12">
          <div className="flex lg:flex-row flex-col gap-12 lg:gap-5">
            {/* Left Side */}
            <div className="md:w-[60%] flex flex-col gap-5">
              {/* Logo here */}
              <div className="flex items-center gap-4">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-pop font-bold text-[1.8rem]">
                  High Hopes
                </div>
              </div>

              {/* Line */}
              <div>
                <p className="text-[15px]">
                  High Hopes is dedicated to providing the highest quality
                  educational experiences and support to our students. With an
                  unwavering commitment to excellence, we aspire to empower
                  every individual to reach their full potential. Our mission is
                  to foster a nurturing and inclusive learning environment where
                  creativity, innovation, and growth thrive. We believe that
                  education is the key to unlocking a brighter future, and we're
                  here to guide, inspire, and uplift our students on their
                  educational journey. Thank you for choosing High Hopes as your
                  partner in education. Together, we reach for the stars and
                  turn dreams into reality.
                </p>
              </div>

              {/* Social Links */}
              <div>
                <ul className="flex items-center gap-5">
                  {socialLinks.map((link, index) => (
                    <li key={index}>
                      <a href={link.path} target="_blank" rel="noreferrer">
                        {link.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Right Side */}
            <div className="lg:w-[40%] flex flex-col gap-5">
              <h5 className="font-medium text-lg">
                Feel free to share your question
              </h5>

              <div className="flex flex-col gap-2">
                {contactLinks.map((link, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 text-sm sm:text-md"
                  >
                    <div className="w-7">
                      <a href={link.path} target="_blank" rel="noreferrer">
                        {link.icon}
                      </a>
                    </div>
                    <a
                      href={link.path}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-sky-500"
                    >
                      {link.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="border-t border-zinc-600 border-opacity-60 w-full"></div>

          <div className="flex justify-between gap-5">
            {/* Left SIde -- Links */}
            <div className="flex flex-col gap-5">
              <h5 className="font-medium text-lg">Useful Links</h5>
              <div>
                <ul className="list-disc px-5 flex flex-col gap-3 text-zinc-200">
                  {usefulLinks.map((link, index) => (
                    <li key={index}>
                      <Link to={link.path} className="hover:text-sky-500">
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#220F28] flex justify-center">
        <div className="w-[80rem] px-5 md:px-10 xl:px-0 text-white text-center py-3 flex flex-col md:flex-row items-center gap-4 justify-between">
          <p className="text-sm">
            &copy; 2023 High Hopes Tutoring. All Rights Reserved.
          </p>
          <div className="flex items-center gap-2">
            <p>Developed by</p>
            <a
              href="https://github.com/w-udhav"
              target="_blank"
              rel="noreferrer"
              className="flex gap-1 hover:text-sky-500 transition-all"
            >
              <span className="font-bold">@w-udhav</span>
              <img src={dev} alt="w-udhav" className="w-6 h-6 rounded-full" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
